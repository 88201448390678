import axios from 'axios';
import './App.css';
import {useState} from 'react'
import { Button, Container, FormFeedback, Input, InputGroup, InputGroupText, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import idTiktok from './img/IDTiktok.jpg'
const axs = axios.create({
  baseURL: "https://rahwana.osdglowcloud.net/"
})

async function postRequest(url, data, config = {}) {
  return await axs
    .post(url, data, config)
    .then((response) => response?.data);
}

function App() {

  const [username, setUsername] = useState("")
  const [whatsapp, setWhatsapp] = useState("")
  const [submitting, setSubmitting] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const [usernameValid, setUsernameValid] = useState()
  const [whatsAppValid, setwhatsAppValid] = useState()

  const [submitMessage, setSubmitMessage] = useState("")
  const onChangeUsername = e => {
    if(!e.target.value) 
      setUsernameValid(false)
    else 
      setUsernameValid(true)

    setUsername(e.target.value)
  }

  const onChangeWhatsApp = e => {
    if(!e.target.value) 
      setwhatsAppValid(false)
    else 
      setwhatsAppValid(true)

    setWhatsapp(e.target.value)
  }

  const onSubmit = async () => {
    setSubmitting(true)
    if(usernameValid && whatsAppValid){
      let tiktokId = username.replace("@",'');
      let tiktokName = username.replace(".",' ').replace("_", " ");
      let noWA = whatsapp
      if(noWA.startsWith("08")){
        noWA = "62" + noWA.slice(1)
      }

      var data = {tiktokId, tiktokName, noWA}

      console.log("sending Data : ", data)
      const result = await postRequest("api/Authentication/RegisterUserTiktok", data)
      if(result){
        setSubmitMessage(result)
      }
    } else {
      alert("Silahkan lengkapi Formulir terlebih dahulu.")
    }
    setSubmitting(false)
  }

  return (
    <div className="App">
      <header className="App-header">
        <Container>
          <div className='head-title' style={{fontSize: "24px"}}>
            Formulir Pendaftaran Tiktok Live <br />Olla Shopping Depok
          </div>
          <br />
          
          <InputGroup>
            <InputGroupText>
              TikTok Id @
            </InputGroupText>
            <Input disabled={submitting} invalid={usernameValid === false} value={username} placeholder="tiktok id / username" required onChange={onChangeUsername} />
            <FormFeedback>
              Silahkan isi usename tiktok anda terlebih dahulu.
            </FormFeedback>
          </InputGroup>
          <br />
          <InputGroup>
            <InputGroupText>
              WhatsApp
            </InputGroupText>
            <Input type="number" pattern="[0-9]*"
            disabled={submitting} invalid={whatsAppValid === false} value={whatsapp} placeholder="contoh : 08123456789" required onChange={onChangeWhatsApp} />
            <FormFeedback>
              Silahkan periksa kembali nomor WhatsApp anda.
            </FormFeedback>
          </InputGroup>

          
          
          <div style={{color: "red"}}>{submitMessage}</div>
          <br />

          
          {submitting && <Spinner color='primary' />}
          <br />
          <Button disabled={submitting} color='primary' onClick={onSubmit}>Submit</Button>

          <div style={{style: "none", margin: "20px", color: "gold"}}>
            Cara mendapatkan id tiktok : <br />
            - Buka aplikasi TikTok dan login akun <br />
          - Pilih menu Profile berikon orang yang berada di bagian kanan bawah layar<br />
          - Pada halaman Profile, kamu akan melihat langsung ID TikTok tepat di bawah foto profil dengan diawali @….., misalnya @ollashoppingdepok<br />
          </div>
          <a href="#" onClick={()=>{setIsOpen(!isOpen)}}>Lihat contoh</a> <br />
          <Modal size='xl' isOpen={isOpen} toggle={()=>{setIsOpen(false)}} centered>
            <ModalHeader>
              Cara Melihat Id Tiktok
            </ModalHeader>
            <ModalBody>
            <img width={"100%"} src={idTiktok} /> 
            </ModalBody>
          </Modal>

          <br /><br />
          <a
            className="App-link"
            href="https://osdcantik.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Visit our online store
          </a>
        </Container>
      </header>
    </div>
  );
}

export default App;
